<template>
  <div>
    <app-page-detail page-title="Edit Addendum Contract" page-toolbar-title="Edit Addendum Contract" :record-not-found="notFound">
      <template #toolbar>
        <app-button @click="getDetail(true)" title="Refresh" mdi-icon="mdi-refresh" :loading="loading"></app-button>
        <!-- <app-button @click="printDocument()" title="Print" mdi-icon="mdi-printer"></app-button> -->
        <app-log-data-dialog module_name="addendum" :id="addendum_header.id">
          <app-button title="Document Log" mdi-icon="mdi-clipboard-text-clock"></app-button>
        </app-log-data-dialog>
        <v-divider vertical class="mx-2"></v-divider>
        <app-button @click="redirect('Finance.Addendum.Detail', { id: id })" title="Detail" mdi-icon="mdi-file-find"></app-button>
        <app-button :loading="loading_save" v-if="addendum_header.status == 'open'" @click="saveData()" mdi-icon="mdi-content-save" title="Save"></app-button>
      </template>
      <v-row v-if="addendum_header.status == 'open'">
        <v-col cols="12" lg="9">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="addendum_header.document_no_" placeholder="No." label="No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="addendum_header.document_date" type="date" placeholder="Doc. Date" label="Doc. Date" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="addendum_header.area_code" placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="addendum_header.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>

                <v-row>
                  <v-col cols="6" md="6">
                    <v-text-field dense v-model="addendum_header.contract_number" placeholder="Nomor Kontrak" label="Nomor Kontrak" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="addendum_header.contract_date" placeholder="Tanggal" label="Tanggal" type="date" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="addendum_header.first_party" placeholder="Pihak Ke Satu" label="Pihak Ke Satu" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="addendum_header.second_party" placeholder="Pihak Ke Dua" label="Pihak Ke Dua" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense v-model="addendum_header.customer_name" placeholder="Project Owner" label="Project Owner" outlined hide-details class="mb-2"></v-text-field>
                    <v-select dense :items="$app_options.finance.addendum.customer_status" v-model="addendum_header.customer_status" label="Customer Status" outlined hide-details class="mb-2"></v-select>

                    <v-text-field dense v-model="addendum_header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2" append-icon="mdi-dots-horizontal" @click:append="tsearch2.dialog = true" readonly></v-text-field>
                    <v-text-field readonly dense v-model="addendum_header.campaign_description" placeholder="Campaign Description" label="Campaign Description" outlined hide-details class="mb-2"></v-text-field>

                    <!-- <v-text-field dense v-model="addendum_header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2"></v-text-field> -->
                    <!-- <v-autocomplete dense v-model="selected_campaign" :search-input.sync="search_campaign" placeholder="Proyek" label="Proyek" return-object :items="campaign_list" item-text="no_" item-value="no_" outlined hide-details class="mb-2" :loading="loading_search_campaign">
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar color="indigo" class="text-h5 font-weight-light white--text">
                          {{ item.description.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.no_ }}</v-list-item-title>
                          <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                    <v-text-field dense v-model="addendum_header.campaign_description" placeholder="Campaign Description" label="Campaign Description" outlined hide-details class="mb-2"></v-text-field> -->
                  </v-col>
                </v-row>
                <v-text-field dense v-model="addendum_header.scope_of_work" placeholder="Lingkup Pekerjaan" label="Lingkup Pekerjaan" outlined hide-details class="mb-2"></v-text-field>
              </card-expansion>
              <card-expansion title="Detail" class="mb-6" :loading="loading">
                <v-row>
                  <v-col>
                    <table style="width: 100%" border="0" cellspacing="5" cellpadding="0">
                      <tr>
                        <th lg="2" cols="12">
                          <span class="d-block my-1">PERUBAHAN</span>
                        </th>
                        <th lg="5" cols="12">
                          <span class="d-block my-1">SEMULA</span>
                        </th>
                        <th lg="5" cols="12">
                          <span class="d-block my-1">MENJADI</span>
                        </th>
                      </tr>
                      <tr>
                        <td lg="2" cols="12">
                          <span class="d-block my-1">JENIS PERJANJIAN</span>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Semula" v-model="addendum_header.before_contract_type" hide-details outlined dense></app-text-field>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Menjadi" v-model="addendum_header.after_contract_type" hide-details outlined dense></app-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td lg="2" cols="12">
                          <span class="d-block my-1">NOMOR PERJANJIAN</span>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Semula" v-model="addendum_header.before_contract_number" hide-details outlined dense></app-text-field>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Menjadi" v-model="addendum_header.after_contract_number" hide-details outlined dense></app-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td lg="2" cols="12">
                          <span class="d-block my-1">TGL PERJANJIAN</span>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Semula" v-model="addendum_header.before_contract_date" type="date" hide-details outlined dense></app-text-field>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Menjadi" v-model="addendum_header.after_contract_date" type="date" hide-details outlined dense></app-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td lg="2" cols="12">
                          <span class="d-block my-1">HARGA</span>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Semula" v-model="addendum_header.before_price" type="number" hide-details outlined dense reverse></app-text-field>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Menjadi" v-model="addendum_header.after_price" type="number" hide-details outlined dense reverse></app-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td lg="2" cols="12">
                          <span class="d-block my-1">WAKTU PENGERJAAN</span>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Semula" v-model="addendum_header.before_work_duration" hide-details outlined dense></app-text-field>
                        </td>
                        <td lg="5" cols="12">
                          <app-text-field label="Menjadi" v-model="addendum_header.after_work_duration" hide-details outlined dense></app-text-field>
                        </td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :addendum_header="addendum_header"></document-info>
          <app-comment :document_id="parseInt(addendum_header.id)" :source_document="addendum_header.source_document" :document_no_="addendum_header.document_no_"></app-comment>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <v-alert type="warning" icon="mdi-information" dense> This document cannot be edited.<br /><a href="javascript:void();" @click="redirect('Finance.Addendum.Detail', { id: id })">View Detail</a> </v-alert>
        </v-col>
      </v-row>
    </app-page-detail>
    <app-table-search-dialog v-model="tsearch2.dialog" title="Search Campaign" :uri="tsearch2.uri" :headers="tsearch2.headers" @row-selected="handleRowSelectedCampaign"></app-table-search-dialog>
  </div>
</template>

<script>
import AppTextField from "@/components/AppTextField.vue";
import DocumentInfo from "./components/DocumentInfo.vue";

export default {
  components: {
    DocumentInfo,
    AppTextField,
  },
  data() {
    return {
      id: null,
      loading: false,
      notFound: false,
      data_detail: {},
      addendum_header: {},
      addendum_approval: [],
      loading_save: false,
      campaign_list: [],
      search_campaign: "",
      loading_search_campaign: false,
      selected_campaign: {
        no_: "",
        description: "",
      },

      tsearch2: {
        uri: "campaign/data",
        dialog: false,
        headers: [
          { text: "No.", value: "no_", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Status", value: "status_code", class: "text-no-wrap" },
        ],
      },
    };
  },
  watch: {
    search_campaign(n) {
      this.getCampaign(n);
    },

    selected_campaign(n) {
      if (typeof n.no_ !== "undefined") {
        this.addendum_header.campaign_no_ = n.no_;
        this.addendum_header.campaign_description = n.description;
      }
    },
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "addendum/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: !refresh,
      };
      await this.$axios
        .get("addendum/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.addendum_header = res.data.data.addendum_header;
          this.addendum_approval = res.data.data.addendum_approval;

          this.selected_campaign.no_ = this.addendum_header.campaign_no_;
          this.selected_campaign.description = this.addendum_header.campaign_description;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async saveData() {
      this.loading_save = true;
      this.showLoadingOverlay(true);
      var data = {
        addendum_header: this.addendum_header,
      };

      await this.$axios
        .post("addendum/save", this.objectToFormData(data))
        .then((res) => {
          this.loading_save = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);
          }
          this.refreshDetail();
        })
        .catch((error) => {
          this.loading_save = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
          this.refreshDetail();
        });
    },

    async getCampaign(search = "") {
      this.loading_search_campaign = true;
      await this.$axios
        .get("addendum/get-campaign", {
          params: {
            search: search,
          },
        })
        .then((res) => {
          this.campaign_list = res.data.data.results;
          this.loading_search_campaign = false;
        })
        .catch((error) => {
          this.loading_search_campaign = false;
          this.axiosErrorHandler(error);
        });
    },

    handleRowSelectedCampaign(item) {
      this.tsearch2.dialog = false;
      this.addendum_header.campaign_no_ = item.no_;
      this.addendum_header.campaign_description = item.description;
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.getDetail(true);
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  vertical-align: top;
  padding-top: 5px;
}

.custom-form-label {
  font-size: 16px;
}
.custom-form-label.dense {
  font-size: 16px !important;
  line-height: 1.375rem;
  /* buat text ada di verical tengah */
  display: flex;
  align-items: center;
}
</style>
